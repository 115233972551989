import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({schema = 'dark', hideText= true} = {}) => {

    return (
        <div style={schema === 'dark' ? {color: '#000'} : {color: '#fff'}}>
            <svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 749 143" xmlSpace="preserve">
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="67.1" y1="328.89" x2="3.4" y2="328.89" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#6EAAC1'}}/>
                    <stop  offset="1" style={{stopColor:'#A1DAF8'}}/>
                </linearGradient>
                <path fill="url(#SVGID_1_)" d="M3.4,7.7h63.7v10.6H3.4V7.7L3.4,7.7z"/>
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="67.1" y1="350.94" x2="3.4" y2="350.94" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#6EAAC1'}}/>
                    <stop  offset="1" style={{stopColor:'#A1DAF8'}}/>
                </linearGradient>
                <path fill="url(#SVGID_2_)" d="M3.4,29.7h63.7v10.7H3.4V29.7L3.4,29.7z"/>
                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="67.1" y1="373.09" x2="3.4" y2="373.09" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#6EAAC1'}}/>
                    <stop  offset="1" style={{stopColor:'#A1DAF8'}}/>
                </linearGradient>
                <path fill="url(#SVGID_3_)" d="M3.4,51.9h63.7v10.6H3.4V51.9L3.4,51.9z"/>
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="109" y1="383.29" x2="109" y2="319.19" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#E30613'}}/>
                    <stop  offset="1" style={{stopColor:'#E84E0F'}}/>
                </linearGradient>
                <path fill="url(#SVGID_4_)" d="M78,55.4L78,55.4c0-10.8,7.3-21.6,7.3-32.4l0,0c0-6.6-2.7-13.1-4.8-19.7h10.6c2.1,6.6,4.8,13.1,4.8,19.7l0,0
                c0,10.8-7.3,21.6-7.3,32.4l0,0c0,4,1,8,2.3,12H80.3C79,63.4,78,59.4,78,55.4z M100,55.4c0-10.8,7.3-21.6,7.3-32.4l0,0
                c0-6.6-2.7-13.1-4.8-19.7h10.6c2.1,6.6,4.8,13.1,4.8,19.7l0,0c0,10.8-7.3,21.6-7.3,32.4l0,0c0,4,1,8,2.3,12h-10.6
                C101,63.4,100,59.4,100,55.4L100,55.4z M122.1,55.4c0-10.8,7.3-21.6,7.3-32.4l0,0c0-6.6-2.7-13.1-4.8-19.7h10.6
                c2.1,6.6,4.8,13.1,4.8,19.7l0,0c0,10.8-7.3,21.6-7.3,32.4l0,0c0,4,1,8,2.3,12h-10.6C123.1,63.4,122.1,59.4,122.1,55.4L122.1,55.4z"/>
                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="70.6" y1="399.94" x2="0.3303" y2="399.94" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#914C15'}}/>
                    <stop  offset="1" style={{stopColor:'#DD663B'}}/>
                </linearGradient>
                <path fill="url(#SVGID_5_)" d="M54.6,85.6L45,95.1l-9.6-9.5l-9.6,9.5l-9.6-9.5l-9.6,9.5l-6.4-6.3l16-15.8l9.6,9.5l9.6-9.5l9.6,9.5l9.6-9.5
                l16,15.8l-6.4,6.3L54.6,85.6z"/>
                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="70.6" y1="422.94" x2="0.3303" y2="422.94" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#914C15'}}/>
                    <stop  offset="1" style={{stopColor:'#DD663B'}}/>
                </linearGradient>
                <path fill="url(#SVGID_6_)" d="M54.6,108.6l-9.6,9.5l-9.6-9.5l-9.6,9.5l-9.6-9.5l-9.6,9.5l-6.4-6.3l16-15.8l9.6,9.5l9.6-9.5l9.6,9.5l9.6-9.5
                l16,15.8l-6.4,6.3L54.6,108.6z"/>
                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="70.6" y1="445.94" x2="0.3303" y2="445.94" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#914C15'}}/>
                    <stop  offset="1" style={{stopColor:'#DD663B'}}/>
                </linearGradient>
                <path fill="url(#SVGID_7_)" d="M54.6,131.6l-9.6,9.5l-9.6-9.5l-9.6,9.5l-9.6-9.5l-9.6,9.5l-6.4-6.3l16-15.8l9.6,9.5l9.6-9.5l9.6,9.5l9.6-9.5
                l16,15.8l-6.4,6.3L54.6,131.6z"/>
                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="76.8" y1="423.89" x2="140.5" y2="423.89" gradientTransform="matrix(1 0 0 1 0 -315.89)">
                    <stop  offset="0" style={{stopColor:'#2581C4'}}/>
                    <stop  offset="1" style={{stopColor:'#00508C'}}/>
                </linearGradient>
                <path fill="url(#SVGID_8_)" d="M128.6,139.3L128.6,139.3c-10.7,0-21.5-7.4-32.2-7.4l0,0c-6.5,0-13,2.7-19.6,4.9v-10.7c6.5-2.1,13-4.9,19.6-4.9
                    l0,0c10.7,0,21.5,7.4,32.2,7.4l0,0c4,0,8-1,11.9-2.3V137C136.5,138.2,132.5,139.3,128.6,139.3z M128.6,117.1
                    c-10.7,0-21.5-7.4-32.2-7.4l0,0c-6.5,0-13,2.7-19.6,4.9v-10.7c6.5-2.1,13-4.9,19.6-4.9l0,0c10.7,0,21.5,7.4,32.2,7.4l0,0
                    c4,0,8-1,11.9-2.3v10.7C136.5,116.1,132.5,117.1,128.6,117.1L128.6,117.1z M128.6,94.8c-10.7,0-21.5-7.4-32.2-7.4l0,0
                    c-6.5,0-13,2.7-19.6,4.9V81.6c6.5-2.1,13-4.9,19.6-4.9l0,0c10.7,0,21.5,7.4,32.2,7.4l0,0c4,0,8-1,11.9-2.3v10.7
                    C136.5,93.7,132.5,94.8,128.6,94.8L128.6,94.8z"/>
                <g style={Object.assign(
                    {},
                    {transition: 'opacity 200ms'},
                    hideText ? {opacity: 0} : {opacity: 1},
                )}>
                    <path fill="currentColor" d="M177.1,125.5h15.4v-2.7h-15.4V125.5z M177.1,112.9h18v-2.8h-18V112.9z M177.1,138.1h18v-2.7h-18V138.1z"/>
                    <path fill="currentColor" d="M242.8,138.1v-2.7h-15.2v-25.3h-2.7v28H242.8z"/>
                    <path fill="currentColor" d="M272.7,125.5h15.4v-2.7h-15.4V125.5z M272.7,112.9h18v-2.8h-18V112.9z M272.7,138.1h18v-2.7h-18V138.1z"/>
                    <path fill="currentColor" d="M349.2,134.4l2.7,3.7v-28l-14.5,11.4l-14.6-11.4v28l2.7-3.7v-18.6l11.8,9.3l11.8-9.3v18.6H349.2z"/>
                    <path fill="currentColor" d="M384.1,125.5h15.4v-2.7h-15.4V125.5z M384.1,112.9h18v-2.8h-18V112.9z M384.1,138.1h18v-2.7h-18V138.1z"/>
                    <path fill="currentColor" d="M437,134.4v-17.5l20.3,21.2v-28l-2.7,3.7v17.5l-20.3-21.2v28L437,134.4z"/>
                    <path fill="currentColor" d="M498.7,138.1v-25.3h9.9v-2.7H486v2.7h9.9v25.3H498.7z"/>
                    <path fill="currentColor" d="M547,137.6c1.1-0.4,2-0.9,2.8-1.6s1.4-1.6,1.9-2.6s0.7-2.2,0.7-3.6c0-1.6-0.3-2.8-1-3.7
                        c-0.7-0.9-1.5-1.6-2.5-2.2c-1-0.5-2.1-1-3.3-1.2c-1.2-0.3-2.3-0.6-3.3-1s-1.9-0.9-2.5-1.4c-0.7-0.6-1-1.4-1-2.5
                        c0-0.9,0.2-1.6,0.5-2.3c0.4-0.6,0.8-1.1,1.4-1.5c0.6-0.4,1.2-0.6,1.9-0.8c0.7-0.2,1.4-0.2,2.1-0.2c0.9,0,1.8,0.1,2.7,0.4
                        c0.9,0.2,1.8,0.7,2.7,1.3l2-2c-1.2-0.9-2.4-1.5-3.6-1.8c-1.2-0.4-2.4-0.5-3.6-0.5c-1.1,0-2.2,0.1-3.2,0.4c-1.1,0.3-2,0.8-2.9,1.4
                        c-0.8,0.6-1.5,1.4-2.1,2.3s-0.8,2-0.8,3.3c0,1.7,0.3,3,1,3.9c0.7,1,1.5,1.7,2.5,2.2s2.1,0.9,3.3,1.2c1.2,0.3,2.3,0.6,3.3,0.9
                        s1.9,0.8,2.5,1.3c0.7,0.6,1,1.4,1,2.5c0,0.9-0.2,1.7-0.6,2.4c-0.4,0.7-0.8,1.3-1.4,1.8c-0.6,0.5-1.2,0.9-1.9,1.1
                        c-0.7,0.3-1.4,0.4-2.1,0.4c-0.9,0-1.8-0.1-2.7-0.4c-0.9-0.2-1.8-0.7-2.8-1.3l-1.9,2c1.2,0.9,2.4,1.5,3.6,1.9
                        c1.2,0.4,2.4,0.6,3.6,0.6C544.7,138.1,545.9,137.9,547,137.6z"/>
                    <path fill="currentColor" d="M223.6,33.3c1.1-2.6,1.6-5.7,1.6-9.4c0-2.7-0.3-5-0.9-7s-1.3-3.6-2.2-5.1c-0.9-1.4-1.8-2.6-2.9-3.5
                        s-2.1-1.7-3.1-2.3c-1.1-0.7-2.2-1.2-3.3-1.6c-1-0.4-2-0.6-2.8-0.8s-1.5-0.3-2.1-0.3c-0.5,0-0.8-0.1-0.9-0.1h-30v7.4h30
                        c0.1,0,0.8,0.1,1.9,0.3c1.1,0.2,2.4,0.7,3.7,1.6c1.3,0.8,2.6,2.2,3.7,3.9c1.1,1.8,1.7,4.3,1.7,7.4s-0.5,5.6-1.6,7.4
                        c-1,1.8-2.2,3.2-3.5,4.1s-2.6,1.6-3.7,1.8c-1.2,0.3-1.9,0.4-2.2,0.4h-30v7.4h36c0.3,0,1,0.1,2.3,0.4c1.2,0.2,2.5,0.8,3.9,1.7
                        s2.6,2.3,3.7,4c1.1,1.8,1.6,4.2,1.6,7.3s-0.5,5.5-1.6,7.3s-2.3,3.1-3.7,4s-2.7,1.4-3.9,1.7c-1.2,0.2-1.9,0.4-2.2,0.4h-35.9v7.4h36.1
                        c0.5,0,1.7-0.2,3.6-0.6c1.8-0.4,3.8-1.2,6-2.5c1-0.6,2.1-1.4,3.2-2.4s2.1-2.2,3-3.6s1.6-3.1,2.2-5s0.9-4.2,0.9-6.7
                        c0-2.9-0.4-5.4-1.1-7.6s-1.7-4-2.8-5.6c-1.1-1.5-2.4-2.8-3.9-3.7c-1.4-0.9-2.9-1.7-4.4-2.2C221.3,37.9,222.6,35.9,223.6,33.3z"/>
                    <path fill="currentColor" d="M299.8,77.9c3.1-0.9,5.6-2,7.7-3.5c2.1-1.4,3.8-3.1,5.1-5.1c1.3-1.9,2.3-3.9,3-6s1.2-4.1,1.4-6.3
                        c0.2-2.1,0.4-4.1,0.4-6V3.2H310v48.9c0,4-0.6,7.2-1.9,9.8c-1.3,2.5-2.9,4.5-4.9,6s-4.3,2.5-6.7,3c-2.5,0.6-4.9,0.9-7.3,0.9
                        c-2.1,0-4.3-0.3-6.6-0.9c-2.3-0.6-4.4-1.6-6.3-3s-3.5-3.4-4.7-6c-1.2-2.5-1.9-5.8-1.9-9.8V3.2H262v47.9c0,2.8,0.3,5.9,0.9,9.1
                        c0.6,3.3,1.8,6.3,3.7,9.1c1.9,2.8,4.6,5.1,8.2,7c3.5,1.9,8.3,2.8,14.2,2.8C293.1,79.2,296.7,78.8,299.8,77.9z"/>
                    <path fill="currentColor" d="M376.3,77.7c3-1,5.6-2.5,7.7-4.4c2.1-2,3.8-4.3,5-7.2c1.2-2.8,1.8-6.1,1.8-9.7c0-4.3-0.9-7.7-2.7-10.2
                        s-4.1-4.4-6.8-5.9c-2.8-1.4-5.7-2.6-8.9-3.4s-6.2-1.7-8.9-2.7c-2.8-1-5-2.3-6.8-3.9s-2.7-3.9-2.7-6.8c0-2.4,0.5-4.5,1.5-6.1
                        c1-1.7,2.2-3,3.8-3.9c1.6-1,3.3-1.7,5.2-2.1s3.8-0.7,5.8-0.7c2.5,0,4.9,0.3,7.4,1c2.4,0.6,4.9,1.8,7.3,3.5l5.3-5.4
                        c-3.4-2.4-6.6-4-9.8-5s-6.4-1.5-9.7-1.5c-3,0-5.9,0.4-8.8,1.2s-5.5,2-7.7,3.7c-2.3,1.7-4.1,3.7-5.6,6.3c-1.4,2.5-2.2,5.5-2.2,9
                        c0,4.5,0.9,8.1,2.7,10.7s4.1,4.6,6.8,6.1c2.8,1.5,5.7,2.5,8.9,3.3c3.2,0.7,6.2,1.6,8.9,2.5c2.8,0.9,5,2.1,6.8,3.6s2.7,3.8,2.7,6.7
                        c0,2.4-0.5,4.6-1.5,6.5c-1,1.9-2.3,3.5-3.8,4.9c-1.5,1.3-3.3,2.3-5.2,3s-3.9,1.1-5.7,1.1c-2.4,0-4.8-0.3-7.3-1
                        c-2.4-0.7-4.9-1.9-7.5-3.5l-5.2,5.4c3.3,2.4,6.5,4,9.7,5.1c3.2,1,6.4,1.5,9.9,1.5C370.1,79.2,373.4,78.7,376.3,77.7z"/>
                    <path fill="currentColor" d="M420.5,3.3v75.9h7.4V3.3H420.5L420.5,3.3z"/>
                    <path fill="currentColor" d="M465.4,69.2V21.7l55.1,57.5V3.3l-7.4,10.1v47.4L458,3.3v75.9L465.4,69.2z"/>
                    <path fill="currentColor" d="M550.5,45.1h41.9v-7.4h-41.9V45.1z M550.5,10.7h48.7V3.3h-48.7V10.7z M550.5,79.2h48.7v-7.4h-48.7V79.2z"/>
                    <path fill="currentColor" d="M658.6,77.7c3-1,5.6-2.5,7.7-4.4c2.1-2,3.8-4.3,5-7.2c1.2-2.8,1.8-6.1,1.8-9.7c0-4.3-0.9-7.7-2.7-10.2
                        s-4.1-4.4-6.8-5.9c-2.8-1.4-5.7-2.6-8.9-3.4s-6.2-1.7-8.9-2.7c-2.8-1-5-2.3-6.8-3.9s-2.7-3.9-2.7-6.8c0-2.4,0.5-4.5,1.5-6.1
                        c1-1.7,2.2-3,3.8-3.9c1.6-1,3.3-1.7,5.2-2.1s3.8-0.7,5.8-0.7c2.5,0,4.9,0.3,7.4,1c2.4,0.6,4.9,1.8,7.3,3.5l5.3-5.4
                        c-3.4-2.4-6.6-4-9.8-5s-6.4-1.5-9.7-1.5c-3,0-5.9,0.4-8.8,1.2c-2.9,0.8-5.5,2-7.7,3.7c-2.3,1.7-4.1,3.7-5.6,6.3
                        c-1.4,2.5-2.2,5.5-2.2,9c0,4.5,0.9,8.1,2.7,10.7s4.1,4.6,6.8,6.1c2.8,1.5,5.7,2.5,8.9,3.3c3.2,0.7,6.2,1.6,8.9,2.5
                        c2.8,0.9,5,2.1,6.8,3.6s2.7,3.8,2.7,6.7c0,2.4-0.5,4.6-1.5,6.5c-1,1.9-2.3,3.5-3.8,4.9c-1.5,1.3-3.3,2.3-5.2,3s-3.9,1.1-5.7,1.1
                        c-2.4,0-4.8-0.3-7.3-1c-2.4-0.7-4.9-1.9-7.5-3.5l-5.2,5.4c3.3,2.4,6.5,4,9.7,5.1c3.2,1,6.4,1.5,9.9,1.5
                        C652.4,79.2,655.6,78.7,658.6,77.7z"/>
                    <path fill="currentColor" d="M731.2,77.7c3-1,5.6-2.5,7.7-4.4c2.1-2,3.8-4.3,5-7.2c1.2-2.8,1.8-6.1,1.8-9.7c0-4.3-0.9-7.7-2.7-10.2
                        s-4.1-4.4-6.8-5.9c-2.8-1.4-5.7-2.6-8.9-3.4s-6.2-1.7-8.9-2.7c-2.8-1-5-2.3-6.8-3.9s-2.7-3.9-2.7-6.8c0-2.4,0.5-4.5,1.5-6.1
                        c1-1.7,2.2-3,3.8-3.9c1.6-1,3.3-1.7,5.2-2.1s3.8-0.7,5.8-0.7c2.5,0,4.9,0.3,7.4,1c2.4,0.6,4.9,1.8,7.3,3.5l5.3-5.4
                        c-3.4-2.4-6.6-4-9.8-5s-6.4-1.5-9.7-1.5c-3,0-5.9,0.4-8.8,1.2c-2.9,0.8-5.5,2-7.7,3.7c-2.3,1.7-4.1,3.7-5.6,6.3
                        c-1.4,2.5-2.2,5.5-2.2,9c0,4.5,0.9,8.1,2.7,10.7s4.1,4.6,6.8,6.1c2.8,1.5,5.7,2.5,8.9,3.3c3.2,0.7,6.2,1.6,8.9,2.5
                        c2.8,0.9,5,2.1,6.8,3.6s2.7,3.8,2.7,6.7c0,2.4-0.5,4.6-1.5,6.5c-1,1.9-2.3,3.5-3.8,4.9c-1.5,1.3-3.3,2.3-5.2,3s-3.9,1.1-5.7,1.1
                        c-2.4,0-4.8-0.3-7.3-1c-2.4-0.7-4.9-1.9-7.5-3.5l-5.2,5.4c3.3,2.4,6.5,4,9.7,5.1c3.2,1,6.4,1.5,9.9,1.5
                        C725,79.2,728.2,78.7,731.2,77.7z"/>
                </g>
            </svg>

        </div>
    );
};

Logo.propTypes = {
    schema: PropTypes.oneOf(['light', 'dark']),
    hideText: PropTypes.bool
};

export default Logo;
