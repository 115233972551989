import React, {useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import { navigate } from "gatsby";
import gsap, {Power4} from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Link = (
    {
        to,
        children,
        className,
        animationDuration = 1,
        stopAnimationsOnScroll = false,
        onPressStart = () => {},
        onAnimationEnd = () => {},
    }) => {
    const linkRef = createRef();

    const nullDefaultListener = (e) => e.preventDefault();

    const stopScrollListener = () => {
        gsap.killTweensOf(window);
        window.removeEventListener('scroll', stopScrollListener)
    };

    useEffect(() => {
        linkRef.current.addEventListener('click', nullDefaultListener);
    }, []);

    const handleClick = async () => {
        if (window.location.pathname === '/') {
            onPressStart();

            stopAnimationsOnScroll && window.addEventListener('wheel', stopScrollListener);

            if (to === 'top') {
                await gsap.to(window, {duration: animationDuration, scrollTo: 0, ease: Power4.easeOut});
            } else {
                await gsap.to(window, {duration: animationDuration, scrollTo: to, ease: Power4.easeOut});
                window.location.replace(to);
            }

            stopAnimationsOnScroll && window.removeEventListener('wheel', stopScrollListener);

            onAnimationEnd()
        } else {
            if (to === 'top') {
                navigate('/');
            } else {
                navigate('/' + to);
            }
        }
    };

    return (
        <a href={'/' + to}
           ref={linkRef}
           className={className}
           onClick={handleClick}
        >
            {children}
        </a>
    )
};

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    animationDuration: PropTypes.number,
    stopAnimationsOnScroll: PropTypes.bool,
    onPressStart: PropTypes.func,
    onAnimationEnd: PropTypes.func,
};

export default Link
