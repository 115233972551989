import React, {useState} from 'react';
import PropTypes from 'prop-types';
import gsap, {Power4} from 'gsap';
import {AnchorLink} from '../Navigation';

import './menu.scss'

export const menuItems = [
    {
        anchorName: 'kdo-jsme',
        title: 'Kdo jsme'
    },
    {
        anchorName: 'pristup',
        title: 'Přístup'
    },
    {
        anchorName: 'sluzby',
        title: 'Služby'
    },
    {
        anchorName: 'portfolio',
        title: 'Portfolio'
    },
    {
        anchorName: 'kariera',
        title: 'Kariéra'
    },
    {
        anchorName: 'kontakt',
        title: 'Kontakt'
    },
];

const Menu = ({schema = 'dark'} = {}) => {
    const [menuOpened, setMenuOpened] = useState(false);

    let menuItemRefs = [];

    const toggleMenu = async () => {
        await setMenuOpened(!menuOpened);

        if (menuOpened) {
            animateMenuItemsOut()
        } else {
            animateMenuItemsIn()
        }
    };

    const animateMenuItemsIn = () => {
        menuItemRefs.forEach((item, index) => {
            if (item) {
                gsap.killTweensOf(item);
                gsap.fromTo(
                    item,
                    {
                        opacity: 0,
                        x: -30
                    },
                    {
                        opacity: 1,
                        x: 0,
                        delay: index / 10,
                        ease: Power4.easeOut
                    }
                );
            }
        })
    };

    const animateMenuItemsOut = () => {
        menuItemRefs.forEach((item, index) => {
            if (item) {
                gsap.killTweensOf(item);
                gsap.fromTo(
                    item,
                    {
                        opacity: 1,
                        x: 0
                    },
                    {
                        opacity: 0,
                        x: 30,
                        delay: index / 40,
                        duration: .3,
                        ease: Power4.easeIn
                    }
                );
            }
        })
    };

    return (
        <div className='c-menu' data-state={menuOpened && 'active'} data-schema={menuOpened && 'light' || schema}>
            <div className='c-menu__hamburger-container' onClick={() => toggleMenu()}>
                <div className='c-menu__hamburger' data-state={menuOpened && 'active'}>
                    <span/><span/><span/>
                </div>
                <p>menu</p>
            </div>
            <div className='c-menu__main' data-state={menuOpened && 'active'}>
                <nav className='c-menu__nav'>
                    <ul className='c-menu__list'>
                        {menuItems.map(({anchorName, title}, index) => (
                            <li key={index} className='c-menu__item' ref={(ref) => menuItemRefs.push(ref)}>
                                <span className='c-menu__item-number'>{`0${index + 1}.`}</span>
                                <AnchorLink
                                    to={`#${anchorName}`}
                                    className='c-menu__link'
                                    onPressStart={toggleMenu}
                                >{title}</AnchorLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
};

Menu.propTypes = {
    schema: PropTypes.oneOf(['light', 'dark']),
};

export default Menu
