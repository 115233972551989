'use strict'

const DATASET = 'state'
const STATE_SEPARATOR = ';'

const hasState = (el, state) => {
    const currentData = el.dataset[DATASET]
    const expr = new RegExp(state, 'g')

    return expr.test(currentData)
}

const getState = (el, type = 'string') => {
    if (type === 'array') {
        return el.dataset[DATASET] ? el.dataset[DATASET].split(STATE_SEPARATOR) : []
    } else {
        return el.dataset[DATASET]
    }
}

const setState = (el, state) => {
    const dataArray = el.dataset[DATASET] ? el.dataset[DATASET].split(STATE_SEPARATOR) : []

    if (!dataArray.includes(state)) {

        dataArray.push(state)
        el.dataset[DATASET] = dataArray.join(STATE_SEPARATOR)

        return 1
    }

    return 0
}

const removeState = (el, state) => {
    const dataArray = el.dataset[DATASET] ? el.dataset[DATASET].split(STATE_SEPARATOR) : []

    if (dataArray.includes(state)) {

        dataArray.splice( dataArray.indexOf(state), 1 );
        el.dataset[DATASET] = dataArray.join(STATE_SEPARATOR)

        return 1
    }

    return 0
}

const toggleState = (el, state, condition = null) => {
    if (condition === null) {
        if (hasState(el, state)) {
            removeState(el, state)
        } else {
            setState(el, state)
        }
    } else {
        if (!condition) {
            removeState(el, state)
        } else {
            setState(el, state)
        }
    }
}

export {
    getState,
    setState,
    hasState,
    removeState,
    toggleState
}
