import PropTypes from 'prop-types';

const isColliding = ( {fixedElement, relativeElement, anchor = 'middle'} = {} ) => {
    if (!fixedElement) return;
    if (!relativeElement) return;

    const fixedElementBounds = fixedElement.getBoundingClientRect();
    const relativeElementBounds = relativeElement.getBoundingClientRect();

    const fixedElementHeight = fixedElementBounds.height;
    const fixedElementY = fixedElementBounds.y;

    const relativeElementHeight = relativeElementBounds.height;
    const relativeElementY = relativeElementBounds.y;

    // Set default anchor deltas
    let anchorDeltaTop = fixedElementHeight / 2;
    let anchorDeltaBottom = fixedElementHeight / 2;

    switch (anchor) {
        case 'outer':
            anchorDeltaTop = fixedElementHeight;
            anchorDeltaBottom = 0;
            break;
        case 'middle':
        default:
            anchorDeltaTop = fixedElementHeight / 2;
            anchorDeltaBottom = fixedElementHeight / 2;
            break;
        case 'inner':
            anchorDeltaTop = 0;
            anchorDeltaBottom = fixedElementHeight;
            break;
    }

    // Return whether it IS colliding
    return fixedElementY + anchorDeltaTop >= relativeElementY
        && fixedElementY + anchorDeltaBottom <= relativeElementY + relativeElementHeight;
};

isColliding.propTypes = {
    fixedElement: PropTypes.node.isRequired,
    relativeElement: PropTypes.node.isRequired,
    anchor: PropTypes.string
};

export { isColliding }
