import { hasState, setState } from '../State';
import AnimateTarget from './target';
import { isViewportReached } from '../isViewportReached';

class Animate {
    static TRIGGER = 'data-animate-trigger';
    static TARGET = 'data-animate-target';
    static ANIMATED_STATE = 'animated';

    constructor(trigger) {
        this.trigger = trigger;
        const targetNodes = this.trigger.querySelectorAll(`[${Animate.TARGET}]`);

        this.targets = [...targetNodes].map(target => new AnimateTarget(target));

        this.render();
        this.bind();
    }

    bind = () => {
        window.addEventListener('wheel', this.render);
        window.addEventListener('scroll', this.render);
        window.addEventListener('resize', this.render);
    }

    render = () => {
        if (isViewportReached(this.trigger)) {
            if (!this.isAnimated()) {
                this.setAnimated();
                this.targets.forEach(target => target.animate())
            }
        }
    }

    isAnimated = () => {
        return hasState(this.trigger, Animate.ANIMATED_STATE)
    };

    setAnimated = () => {
        setState(this.trigger, Animate.ANIMATED_STATE)
    };

    static initGlobal = () => {
        const elements = document.querySelectorAll(`[${Animate.TRIGGER}]`);

        return [...elements].map(element => new Animate(element))
    }
}

export default Animate
