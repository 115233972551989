
const isViewportReached = (el, options = {}) => {
    if (!el) return;

    const viewportHeight = window.innerHeight;
    const elHeight= el.offsetHeight;

    const defaultOptions = {
        offset: 50,
    };

    options = Object.assign({}, defaultOptions, options);

    const elRects = el.getBoundingClientRect();

    const topEntered = elRects.y + elHeight - options.offset >= 0
    const bottomEntered = elRects.y - viewportHeight + options.offset <= 0

    return topEntered && bottomEntered
}

export {
    isViewportReached
}
