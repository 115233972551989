import React from 'react'
import {AnchorLink} from '../Navigation';
import {menuItems} from '../Menu';

import './footer.scss';

const Footer = () => (
    <footer className='c-footer' data-animate-trigger>
        <nav data-animate-target
             data-animate-from={JSON.stringify({opacity: 0})}
             data-animate-to={JSON.stringify({opacity: 1, duration: 1})}
        >
            <ul className='c-footer__menu'>
                <li className='c-footer__menu-item'><AnchorLink to={'top'} className='o-link'>Úvod</AnchorLink></li>
                {menuItems.map(({anchorName, title}, index) => (
                    <li key={index} className='c-footer__menu-item'><AnchorLink to={`#${anchorName}`} className='o-link'>{title}</AnchorLink></li>
                ))}
            </ul>
        </nav>
        <div className='c-footer__copy'
             data-animate-target
             data-animate-from={JSON.stringify({opacity: 0})}
             data-animate-to={JSON.stringify({opacity: 1, duration: 1})}
        >© 2020 MV Business Elements, s.r.o. Design & Development by MadFox design.</div>
    </footer>
);

export default Footer;
