import React, {createRef, useEffect} from 'react';
import PropTypes from 'prop-types';

const Area = ({anchorName, children, className} = {}) => {
    let ref = createRef();

    useEffect(() => {
        const sectionEl = ref.current
        window.addEventListener('scroll', () => {
            const {height, y} = sectionEl.getBoundingClientRect();

            if (
                (y <= 1 && y + height > 0) &&
                window.location.hash !== `#${sectionEl.id}`
            ) {
                window.history.replaceState(
                    null,
                    null,
                    sectionEl.id === '' ? ' ' : `#${sectionEl.id}`,
                );
            }
        })
    });

    return (
        <div
            id={anchorName}
            className={className}
            ref={ref}
        >
            {children}
        </div>
    )
};

Area.propTypes = {
    anchorName: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Area;
