import { setState, removeState } from '../State';
import gsap from 'gsap';

class AnimateTarget {
    static PROPS = {
        type: 'animateType',
        from: 'animateFrom',
        to: 'animateTo',
    };

    constructor(target) {
        if (!target) return;

        this.target = target;
        this.from =
            target.dataset[AnimateTarget.PROPS.from] ?
                JSON.parse(target.dataset[AnimateTarget.PROPS.from]) :
                {y: 40, opacity: 0};
        this.to =
            target.dataset[AnimateTarget.PROPS.to] ?
                JSON.parse(target.dataset[AnimateTarget.PROPS.to]) :
                {y: 0, opacity: 1};

        gsap.set(target, this.from)
    }

    animate = async () => {
        setState(this.target, 'animating');

        await gsap.to(this.target, this.to);

        removeState(this.target, 'animating');
        setState(this.target, 'animated');

        this.clear()
    };

    clear = () => {

    }
}

export default AnimateTarget
