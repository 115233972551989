import React, {useEffect} from "react"
import * as PropTypes from "prop-types"
import {setLocationOnLoad} from '../Navigation';
import Animate from '../../helpers/Animate';
import Header from '../Header';
import Footer from '../Footer';
import ContactModal from '../ContactModal';

import '../../styles/core.scss';
import './layout.scss'

const Layout = ({ children } = {}) => {

    useEffect(() => {

        setLocationOnLoad();

        Animate.initGlobal();

    }, []);

    return (
        <div className='c-layout c-layout--guides'>
            <Header/>

            <main>{children}</main>

            <Footer />

            <ContactModal />
        </div>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
