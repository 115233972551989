import React, {createRef} from 'react'
import Menu from '../Menu'
import {isColliding} from '../../helpers/isColiding';

import './header.scss'
import Logo from '../Logo';
import {Link} from 'gatsby';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colorSchema: 'dark'
        };

        this.headerRef = createRef();
    }

    componentDidMount = () => {
        this.coliders = document.querySelectorAll('[data-header-schema]');

        this.resolveSchemaListener();
        window.addEventListener('scroll', this.resolveSchemaListener)
    };

    resolveSchemaListener = () => {
        const headerEl = this.headerRef.current;
        const {colorSchema} = this.state;

        this.coliders.forEach((colider) => {
            const coliderSchema = colider.dataset.headerSchema;

            if (isColliding({fixedElement: headerEl, relativeElement: colider}) && colorSchema !== coliderSchema) {

                this.setState({
                    colorSchema: coliderSchema,
                })
            }
        });
    };

    render = () => (
        <header className='c-header' ref={this.headerRef}>
            <div className='c-header__inner'>
                <div className='c-header__logo'>
                    <Link to={'/'} aria-label={"Zpět na homepage"}>
                        <Logo schema={this.state.colorSchema} />
                    </Link>
                </div>
                <div className='c-header__menu'>
                    <Menu schema={this.state.colorSchema} />
                </div>
            </div>
        </header>
    )
}

export default Header
