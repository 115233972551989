import React, {createRef, useContext, useState} from 'react';
import {
    GlobalDispatchContext,
    GlobalStateContext,
} from '../../context/GlobalContextProvider';

import './contact-modal.scss';

const formUrl = 'https://getform.io/f/292a94ba-d6fb-441c-bcf9-42340a350d58';

const ContactModal = () => {
    const [formSend, setFormSend] = useState(false);
    const [formError, setFormError] = useState(false);
    const [isFormSending, setIsFormSending] = useState(false);
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const form = createRef();

    const handleFormSend = (e) => {
        e.preventDefault();

        setIsFormSending(true);

        const formData = new FormData(form.current);

        const XHR = new XMLHttpRequest();

        XHR.addEventListener( 'load', () => {
            setFormSend(true);
            setIsFormSending(false);
        });

        XHR.addEventListener(' error', () => {
            setFormError(true);
            setIsFormSending(false);
        });

        XHR.open('POST', formUrl);

        XHR.send(formData);
    };

    return (
        <div className="c-contact-modal" data-state={state.contactModalOpened && 'opened'}>
            <div className="c-contact-modal__inner">
                <p className="c-contact-modal__heading">Kontaktujte nás</p>
                <p className="c-contact-modal__subheading">Můžete použít formulář níže a nebo napsat přímo na e-mail <a
                    href="mailto:info@bussinesselements.cz" className="o-link o-link--red">info@bussinesselements.cz</a></p>
                {!formSend && (
                    <form ref={form} className="c-contact-modal__form">
                        <p className="c-contact-modal__form-group">
                            <label htmlFor="name" className="c-contact-modal__label">Jméno</label>
                            <input id="name" name="name" type="text" className="c-contact-modal__input" required={true}/>
                        </p>
                        <p className="c-contact-modal__form-group c-contact-modal__form-group--half">
                            <label htmlFor="email" className="c-contact-modal__label">E-mail</label>
                            <input id="email" name="email" type="email" className="c-contact-modal__input" required={true}/>
                        </p>
                        <p className="c-contact-modal__form-group c-contact-modal__form-group--half">
                            <label htmlFor="phone" className="c-contact-modal__label">Telefon</label>
                            <input id="phone" name="phone" type="tel" className="c-contact-modal__input" required={true}/>
                        </p>
                        <p className="c-contact-modal__form-group">
                            <label htmlFor="message" className="c-contact-modal__label">Zpráva</label>
                            <textarea id="message" name="message" rows={5} className="c-contact-modal__input"/>
                        </p>
                        <p className="c-contact-modal__form-group c-contact-modal__form-group--button">
                            <button name="submit" className="o-button" onClick={handleFormSend}>Odeslat</button>
                        </p>
                    </form>
                )}

                {formSend && (
                    <p>Vaše zpráva byla úspěšně odeslána. V blízké době Vás budeme kontaktovat. Děkujeme.</p>
                )}

                {formError && (
                    <p>Vaši zprávu se bohužel nepodařilo odeslat. Zkontrolujte zda máte všechna pole vyplněna, případně pro kontakt využijte e-mail <a
                        href="mailto:info@bussinesselements.cz" className="o-link o-link--red">info@bussinesselements.cz</a>. Děkujeme.</p>
                )}

                {isFormSending && (
                    <p className="c-contact-modal__form-is-sending">Formulář se odesílá...</p>
                )}
                <span className="c-contact-modal__close" onClick={() => {
                    dispatch({ type: 'CLOSE_MODAL' })
                }}>&times;</span>
            </div>
            <span className="c-contact-modal__clone" onClick={() => {
                dispatch({ type: 'CLOSE_MODAL' })
            }}/>
        </div>
    )
};

export default ContactModal
