import PropTypes from 'prop-types';
import gsap, {Power4} from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const setLocationOnLoad = async (
    {
        animate = false,
        animateDuration = 1,
    } = {}) => {
    const hash = window.location.hash;

    if (animate) {
        await gsap.to(window, {duration: animateDuration, scrollTo: hash, ease: Power4.easeOut})
    } else {
        await gsap.set(window, {scrollTo: hash})
    }
};

setLocationOnLoad.propTypes = {
    animate: PropTypes.bool,
    animateDuration: PropTypes.number,
};

export default setLocationOnLoad
